import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import programPlanner from '@/store/modules/programPlannerStore';
import commonEntry from '@/store/modules/commonEntry';
import { IProgramEvent } from '@/Model/programModel';
import APP_UTILITIES from '@/utilities/commonFunctions';
import APP_CONST from '@/constants/AppConst';
import { createHolidays, editHolidays } from '@/services/attedance/attedanceService';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';

@Component({
  components: {
    'vue-timepicker': VueTimepicker,
    'datepicker': DatepickerComponent
  }
})
export default class ProgramEventComponent extends Vue {
  private objScreenText: ScreenText = new ScreenText();
  public plannerEventObj: IProgramEvent = {
    id: 0,
    programId: 0,
    siteId: 0,
    title: '',
    location: '',
    startDate: '',
    endDate: '',
    description: '',
    status: {
      id: 1,
      state: 'Active'
    },
    createdBy: 0,
    createdAt: '',
  } as IProgramEvent;
  validatedFields = JSON.parse(JSON.stringify(APP_CONST.VALIDATE_PROG_PLAN_EVENT));
  yourFormat: string = 'hh:mm A';
  programStartDate: string = '';
  programEndDate: string = '';
  startSideString: string = '';
  endSideString: string = '';
  start_time: any = '';
  end_time: string = '';
  start_time_error: boolean = false;
  end_time_error: boolean = false;
  start_date_error: boolean = false;
  checkForValidityWithProgram: boolean = false;
  public currentSiteId: any = 0;
  public currentRoleId: any = 0;
  public userRoles: any = [];
  public programId: any = 0;
  holidayForm: boolean = false;
  errorText: string = '';
  private getMonths: any = APP_CONST.GET_MONTHS;
  public openTimeDrop: boolean = false;
  public openEndTimeDrop: boolean = false;
  public timeList: any = [];
  public startTimeBlurr = false;
  public endTimeBlurr = false;
  public allFieldRequired: boolean = false;
  public timeError: boolean = false;
  public negativeTimeError: boolean = false;
  public ampmError: boolean = false;
  public dateType: string = '';
  public dateResponseError: boolean = APP_CONST.FALSE;
  public dateResponse: string = '';

  @Prop()
  EventObjToUpdate!: any;

  @Prop()
  userDetails!: any;

  get updatedEventsChange() {
    return this.plannerEventObj;
  }

  get plannerEventRes() {
    return programPlanner.EventAddResponse;
  }

  get getUserDetail() {
    return commonEntry.userDetail;
  }


  get UpdatedplannerEventRes() {
    return programPlanner.UpdatedEvent;
  }

  beforeMount() {
    this.getProgramId();
    this.getSiteId();
    const user_id = APP_UTILITIES.getCookie(APP_CONST.USER_ID);
    if (user_id) {
      commonEntry.fetchUserDetails(parseInt(user_id));
    }
    this.currentRoleId = this.userDetails.roleId;
    this.currentSiteId = this.userDetails.siteId;
    this.plannerEventObj.programId = this.userDetails.programId;
    this.plannerEventObj.siteId = (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
      ? this.currentSiteId
      : 0;
    this.programStartDate = this.userDetails.startDate;
    this.programEndDate = this.userDetails.endDate;
  }

  public closeEvent() {
    this.plannerEventObj.title = '';
    this.plannerEventObj.startDate = '';
    this.plannerEventObj.description = '';
    this.start_time = '';
    this.end_time = '';
    this.errorText = '';
    this.allFieldRequired = APP_CONST.FALSE;
    this.updatationTime();
    this.holidayForm = APP_CONST.FALSE;
    this.timeError = APP_CONST.FALSE;
    this.negativeTimeError = APP_CONST.FALSE;
    this.validatedFields = JSON.parse(JSON.stringify(APP_CONST.VALIDATE_PROG_PLAN_EVENT));
    this.$emit('togglePanel', {});
    const scroll: any = document.getElementById('offcanvas-flip-event-scroll');
    scroll.scrollTop = 0;
    this.allFieldRequired = APP_CONST.FALSE;
    this.dateResponseError = APP_CONST.FALSE;
    this.dateResponse = '';
    this.dateType = '';
  }

  public changeViewToTask() {
    this.closeEvent();
    this.$emit('openTask');
  }

  public getProgramId() {
    let pID = 0;
    const userRoles: any = APP_UTILITIES.getCookie('user_role');
    this.userRoles = JSON.parse(userRoles);
    this.userRoles.forEach((item: any, index: number) => {
      if (item.hasOwnProperty('programId')) {
        pID = item.programId;
      }
      else {
        const programId: any = APP_UTILITIES.getCookie('programId');
        pID = Number(programId);
      }

      if (item.hasOwnProperty('roleId')) {
        this.currentRoleId = item.roleId;
      }

    });
    return this.programId = pID;
  }

  public getSiteId() {
    const userRoles: any = APP_UTILITIES.getCookie('user_role');
    this.userRoles = JSON.parse(userRoles);
    this.userRoles.forEach((item: any, index: number) => {
      if (item.hasOwnProperty('siteId')) {
        this.currentSiteId = item.siteId;
      }
      else {
        const siteId: any = APP_UTILITIES.getCookie('siteId');
        this.currentSiteId = Number(siteId);
      }
    });
  }
  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  @Watch('EventObjToUpdate', { deep: true, immediate: true })
  bindUpdatedTask(data: any) {

    if (data.id) {
      this.plannerEventObj = { ...data };
      this.start_time = data.startTime
        ? data.startTime.replace(/^0+/, '')
        : '7:00 AM';
      this.end_time = data.endTime
        ? data.endTime.replace(/^0+/, '')
        : '7:30 AM';
      this.endSideString = this.end_time.split(' ')[APP_CONST.ONE];
      this.holidayForm = data.holiday;
    }
    else {
      const programId = this.plannerEventObj.programId;
      this.plannerEventObj = { ...data };
      this.plannerEventObj.programId = programId;
      this.plannerEventObj.siteId = (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
        ? this.currentSiteId
        : 0;
      this.start_time = '7:00 AM';
      this.end_time = '7:30 AM';
    }
  }

  public convertTime12to24(time12h: string) {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes]: any = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  public convertTime24to12(time24h: string) {
    let [hours, minutes]: any = time24h.split(':');
    const ampm = hours >= 12
      ? 'PM'
      : 'AM';
    hours = hours % 12;
    hours = hours
      ? (hours < 10
        ? '0' + hours
        : hours)
      : 12;
    minutes = minutes.length < 2
      ? '0' + minutes
      : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  diff_minutes(dt2: Date, dt1: Date) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.round(diff);

  }

  public validateTime(start_time: string, end_time: string) {
    const start_hh_mm = this.convertTime12to24(start_time).split(':');
    const end_hh_mm = this.convertTime12to24(end_time).split(':');
    const startHour = +start_hh_mm[0], startMinute = +start_hh_mm[1], endHour = +end_hh_mm[0], endMinute = +end_hh_mm[1];

    const startTimeObject: any = new Date();
    startTimeObject.setHours(startHour, startMinute, APP_CONST.ZERO, APP_CONST.ZERO);


    const endTimeObject: any = new Date();
    endTimeObject.setHours(endHour, endMinute, APP_CONST.ZERO, APP_CONST.ZERO);
    const diffMins = this.diff_minutes(endTimeObject, startTimeObject);
    if ((diffMins <= 30) || (start_time && !end_time) || (!start_time && end_time)) {
      return true;
    }
    else {
      return false;
    }
  }

  startTimeChange(ev: any) {
    if (!this.startTimeBlurr) {
      return;
    }
    this.startTimeBlurr = false;
    if (ev.target.textContent) {
      this.start_time_error = false;
      this.updateTime('start_time', ev.target.textContent.trim());
    }
    else {
      this.start_time = ev.target.textContent.trim();
    }
  }

  endTimeChange(ev: any) {
    if (!this.endTimeBlurr) {
      return;
    }
    this.endTimeBlurr = false;
    if (ev.target.textContent) {
      this.end_time_error = false;
      this.updateTime('end_time', ev.target.textContent.trim());
    }
    else {
      this.end_time = ev.target.textContent.trim();
    }
  }

  checkLength($event: any) {
    if (($event.target.textContent.length > 9) && (($event.keyCode !== 8))) {
      $event.preventDefault();
    }
  }

  public onInputStartTime($event: any) {
    if ($event.target.textContent.length > 10) {
      $event.preventDefault();
      return false;
    }
    else {
      this.startTimeBlurr = true;
    }
  }

  public onInputEndTime() {
    this.endTimeBlurr = true;
  }

  public updateTime(type: string, time: any) {
    let startTimeArray = [], endTimeArray = [], ampm = '', hh_mm = [], hh: number = 0, mm = 0, end_mm = '', end_hh = 0;
    this.openTimeDrop = false;
    if (type == 'start_time') {
      this.start_time = time;
      const startStatus = this.validateTime(this.start_time, this.end_time);
      if (!startStatus) {
        return;
      }
      startTimeArray = this.start_time.split(' ');
      ampm = startTimeArray[1] || '';
      hh_mm = startTimeArray[APP_CONST.ZERO].split(':');
      hh = +hh_mm[0];
      mm = +hh_mm[1];
      if (mm < 30) {
        end_mm = `${Number(mm) + 30}`;
        this.end_time = hh + ':' + end_mm + ' ' + ampm;
      }
      else if (mm == 30) {
        end_mm = '00';
        if (hh == 11) {
          if (ampm == 'AM') {
            ampm = 'PM';
          }
          else if (ampm == 'PM') {
            ampm = 'AM';
          }
        }
        if (hh == 12) {
          end_hh = 1;
        }
        else {
          end_hh = Number(hh) + 1;
        }
        this.end_time = end_hh + ':' + end_mm + ' ' + ampm;
      }
      else if (mm > 30) {
        end_mm = `${mm - 30}`;
        if (hh == 11) {
          if (ampm == 'AM') {
            ampm = 'PM';
          }
          else if (ampm == 'PM') {
            ampm = 'AM';
          }
        }
        if (hh == 12) {
          end_hh = 1;
        }
        else {
          end_hh = Number(hh) + 1;
        }
        this.end_time = end_hh + ':' + end_mm + ' ' + ampm;
      }
    }
    else if (type == 'end_time') {
      this.openEndTimeDrop = false;
      this.end_time = time;
      const endStatus = this.validateTime(this.start_time, this.end_time);
      if (!endStatus) {
        return;
      }
      endTimeArray = this.end_time.split(' ');
      ampm = endTimeArray[1] || '';
      hh_mm = endTimeArray[APP_CONST.ZERO].split(':');
      hh = +hh_mm[0];
      mm = +hh_mm[1];

      if (mm < 30) {
        end_mm = `${Number(mm) + 30}`;
        if (hh == 12) {
          if (ampm == 'AM') {
            ampm = 'PM';
          }
          else if (ampm == 'PM') {
            ampm = 'AM';
          }
        }
        if (hh == 1) {
          end_hh = 12;
        }
        else {
          end_hh = Number(hh) - 1;
        }
        this.start_time = end_hh + ':' + (+end_mm < 0
          ? (+end_mm * -1)
          : end_mm) + ' ' + ampm;
      }
      else if (mm == 30) {
        end_mm = '00';
        this.start_time = hh + ':' + end_mm + ' ' + ampm;
      }
      else if (mm > 30) {
        end_mm = `${mm - 30}`;
        this.start_time = hh + ':' + end_mm + ' ' + ampm;
      }
    }
  }

  public changeHandler(eventObject: any, type: string) {

    const timeSplit = eventObject.displayTime.split(' ');
    if (type === 'startTime') {
      this.start_time = eventObject.displayTime;
      this.startSideString = timeSplit[APP_CONST.ONE];
      const startNumberTime = timeSplit[APP_CONST.ZERO].split(':');
      if (typeof (parseInt(startNumberTime[APP_CONST.ZERO])) == 'number' && typeof (parseInt(startNumberTime[APP_CONST.ONE])) == 'number') {
        let generateEndMinutesGap = parseInt(startNumberTime[APP_CONST.ONE]) <= 30
          ? JSON.stringify(parseInt(startNumberTime[APP_CONST.ONE]) + 30)
          : JSON.stringify((parseInt(startNumberTime[APP_CONST.ONE]) + 30) - 60);
        let generateEndHour = (parseInt(startNumberTime[APP_CONST.ONE]) + 30) > 60
          ? JSON.stringify(parseInt(startNumberTime[APP_CONST.ZERO]) + APP_CONST.ONE).length === APP_CONST.ZERO
            ? '0' + JSON.stringify(parseInt(startNumberTime[APP_CONST.ZERO]) + APP_CONST.ONE)
            : JSON.stringify(parseInt(startNumberTime[APP_CONST.ZERO]) + APP_CONST.ONE)
          : startNumberTime[APP_CONST.ZERO];
        generateEndHour = parseInt(generateEndHour) > 12
          ? JSON.stringify(parseInt(generateEndHour) - 12)
          : generateEndHour;
        if (parseInt(startNumberTime[APP_CONST.ZERO]) == 11 && parseInt(startNumberTime[APP_CONST.ONE]) > 30) {
          this.endSideString = this.startSideString == 'AM'
            ? 'PM'
            : 'AM';
        }
        else {
          this.endSideString = this.startSideString;
        }
        generateEndHour = generateEndHour.length == 1
          ? '0' + generateEndHour
          : generateEndHour;
        generateEndMinutesGap = generateEndMinutesGap.length == 1
          ? '0' + generateEndMinutesGap
          : generateEndMinutesGap;
        this.end_time = generateEndHour + ':' + generateEndMinutesGap + ' ' + this.endSideString;
      }
    }
    else {
      this.end_time = eventObject.displayTime;
      this.endSideString = timeSplit[APP_CONST.ONE];

    }
  }

  @Watch(APP_CONST.GET_USER_DT, { deep: true })
  getProgId(data: any) {

  }

  addProgramEvent() {
    let checkForCorrectTime = false;
    this.checkForValidityWithProgram = false;
    this.start_time_error = false;
    this.end_time_error = false;
    this.timeError = false;
    this.negativeTimeError = false;
    this.ampmError = false;
    this.start_date_error = false;
    const startTimeSplit = this.start_time.split(' ');
    const endTimeSplit = this.end_time.split(' ');
    const dateNumbers = startTimeSplit[APP_CONST.ZERO].split(':');
    const endTimeNumbers = endTimeSplit[APP_CONST.ZERO].split(':');
    const regexFilter: any = /^[-+]?[0-9]+$/;

    if (this.plannerEventObj.startDate.length === 0 || (typeof (this.plannerEventObj.startDate) == 'string' && this.plannerEventObj.startDate.includes('NaN'))) {
      this.start_date_error = true;
    }
    if (this.start_time.length > 0 && dateNumbers[APP_CONST.ZERO].match(regexFilter) && dateNumbers[APP_CONST.ONE].match(regexFilter)) {

      const newStartDate = this.plannerEventObj.startDate
        ? APP_UTILITIES.formatCorrectDate(this.plannerEventObj.startDate).split(' ')
        : '';
      this.plannerEventObj.startDate = newStartDate
        ? `${newStartDate[APP_CONST.ZERO]} ${this.convertTime12to24(this.start_time)}`
        : '';

    }
    else {
      checkForCorrectTime = true;
      this.start_time_error = true;
    }
    if (this.end_time.length > 0 && endTimeNumbers[APP_CONST.ZERO].match(regexFilter) && endTimeNumbers[APP_CONST.ONE].match(regexFilter)) {
      this.plannerEventObj.endDate = APP_UTILITIES.formatCorrectDate(this.plannerEventObj.startDate);

      const newEndDate = APP_UTILITIES.formatCorrectDate(this.plannerEventObj.endDate).split(' ');
      this.plannerEventObj.endDate = `${newEndDate[APP_CONST.ZERO]} ${this.convertTime12to24(this.end_time)}`;

    }
    else {
      checkForCorrectTime = true;
      this.end_time_error = true;
    }

    if (this.start_time.length > 0) {
      const startTime = this.start_time.split(' ');
      if (startTime[1] === 'AM' || startTime[1] === 'PM') {
        this.ampmError = false;
      }
      else {
        this.ampmError = true;
        return false;
      }
    }

    if (this.end_time.length > 0) {
      const endTime = this.end_time.split(' ');
      if (endTime[1] === 'AM' || endTime[1] === 'PM') {
        this.ampmError = false;
      }
      else {
        this.ampmError = true;
        return false;
      }
    }

    const ObjPlannerEvent = { ...this.plannerEventObj } as any;
    const ValidateObj = this.validatedFields;
    const userDetails: any = commonEntry.userDetail;
    ObjPlannerEvent['userRoles'] = userDetails.userRoles;
    ObjPlannerEvent['siteId'] = (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
      ? this.currentSiteId
      : 0;

    const checkValidation = APP_UTILITIES.requiredFields(ObjPlannerEvent, ValidateObj);
    this.validatedFields = { ...checkValidation.validateData };

    if (checkValidation.isFormInvalid) {
      this.allFieldRequired = checkValidation.isFormInvalid;
      return;
    }
    if (this.plannerEventObj.endDate.length && this.plannerEventObj.startDate.length) {
      const dateA = new Date(this.plannerEventObj.startDate);
      const dateB = new Date(this.plannerEventObj.endDate);

      const dayRelativeDifference = dateB.getHours() * 60 + dateB.getMinutes()
        - dateA.getHours() * 60 - dateA.getMinutes();
      if (dayRelativeDifference < 0) {
        this.negativeTimeError = true;
        this.allFieldRequired = APP_CONST.FALSE;
        return;
      }
      else if (dayRelativeDifference >= 0 && dayRelativeDifference < 30) {
        this.timeError = true;
        this.allFieldRequired = APP_CONST.FALSE;
        return;

      }
    }
    if (this.plannerEventObj.startDate.length === 0 || (this.plannerEventObj.title && this.plannerEventObj.title.length === 0) || checkForCorrectTime) {
      return;
    }

    ObjPlannerEvent.startTime = this.start_time;
    ObjPlannerEvent.endTime = this.end_time;
    if (this.plannerEventObj.id) {
      this.timeError === APP_CONST.FALSE && this.negativeTimeError === APP_CONST.FALSE
        ? this.updateProgramEvent(ObjPlannerEvent)
        : '';
    }
    else {
      programPlanner.addProgramPlannerEvent(ObjPlannerEvent as IProgramEvent).then(() => {
        if (programPlanner.EventAddResponse.status === APP_CONST.RESPONSE_200) {
          const updatedEventData = { ...programPlanner.EventAddResponse.data, state: 'addEvent' };
          ObjPlannerEvent.id = programPlanner.EventAddResponse.data.id;
          updatedEventData.startTime = ObjPlannerEvent.startTime;
          updatedEventData.endTime = ObjPlannerEvent.endTime;
          this.$emit('openPopup', updatedEventData);
          const programStartDate = this.programStartDate;
          const programEndDate = this.programEndDate;
          this.plannerEventObj = { ...APP_CONST.PROGRAM_EVENT } as any;
          this.programStartDate = programStartDate;
          this.programEndDate = programEndDate;
          this.plannerEventObj.programId = ObjPlannerEvent.programId;
          this.closeEvent();
        }
        else {
          this.closeEvent();
        }
      });
    }


  }

  checkForCorrectDate(type: string) {
    let startDate = this.plannerEventObj.startDate;
    this.dateType = '';
    this.dateResponseError = APP_CONST.FALSE;
    if (type === APP_CONST.END_DATE) {
      if (startDate.length === APP_CONST.ZERO) {
        this.dateResponseError = APP_CONST.TRUE;
        this.dateResponse = APP_CONST.FILL_START;
        this.allFieldRequired = APP_CONST.FALSE;
        this.dateType = 'startDate';
        setTimeout(() => {
          startDate = '';
          this.plannerEventObj.startDate = '';
          this.plannerEventObj.endDate = '';
        });
      }
      if (Date.parse(this.plannerEventObj.endDate) < Date.parse(this.plannerEventObj.startDate)) {
        this.dateResponseError = APP_CONST.TRUE;
        this.dateResponse = APP_CONST.ALERT_END_DATE;
        this.allFieldRequired = APP_CONST.FALSE;
        this.dateType = type;
        setTimeout(() => {
          this.plannerEventObj.endDate = '';
        });
      }
    }
    else {
      if (Date.parse(this.plannerEventObj.endDate) < Date.parse(this.plannerEventObj.startDate)) {
        this.dateResponseError = APP_CONST.TRUE;
        this.dateResponse = APP_CONST.ALERT_END_DATE;
        this.allFieldRequired = APP_CONST.FALSE;
        this.dateType = 'endDate';
        setTimeout(() => {
          this.plannerEventObj.endDate = '';
        });
      }
    }
  }

  refillForm() {
    const obj = { programId: this.plannerEventObj.programId, programStartDate: this.programStartDate, programEndDate: this.programEndDate };
    this.plannerEventObj = { ...APP_CONST.PROGRAM_EVENT } as IProgramEvent;
    this.plannerEventObj.programId = obj.programId;
    this.plannerEventObj.siteId = (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
      ? this.currentSiteId
      : 0;
    this.programStartDate = obj.programStartDate;
    this.programEndDate = obj.programEndDate;
  }

  back() {
    if (this.plannerEventObj.id) {
      this.$emit(APP_CONST.BACK_TO_ADD);
    }
    else {
      this.$emit(APP_CONST.BACK_BUTTON);
      this.refillForm();
    }
    this.allFieldRequired = APP_CONST.FALSE;
    this.dateResponseError = APP_CONST.FALSE;
    this.dateResponse = '';
    this.dateType = '';
    const scroll: any = document.getElementById('offcanvas-flip-event-scroll');
    scroll.scrollTop = 0;
  }

  cancelUpdation() {
    this.closeEvent();
    this.$emit(APP_CONST.CLOSE);
  }

  updateProgramEvent(ObjPlannerEvent: IProgramEvent) {
    programPlanner.updatedPlannerEvent(ObjPlannerEvent as IProgramEvent).then(() => {
      if (programPlanner.UpdatedEvent.status === APP_CONST.RESPONSE_200) {
        const updatedEventData = { ...ObjPlannerEvent, state: 'updateEvent' };
        this.$emit('openPopup', updatedEventData);
        this.plannerEventObj = { ...APP_CONST.PROGRAM_EVENT } as any;
        this.plannerEventObj.programId = ObjPlannerEvent.programId;
        this.plannerEventObj.siteId = (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
          ? this.currentSiteId
          : 0;
        this.EventObjToUpdate.programId = ObjPlannerEvent.programId;
        this.EventObjToUpdate.siteId = (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
          ? this.currentSiteId
          : 0;
        this.updatationTime();
        this.closeEvent();
      }
    });
  }

  async createHoliday() {
    const ObjPlannerEvent = { ...this.plannerEventObj } as any;
    const ValidateObj = this.validatedFields;
    const checkValidation = APP_UTILITIES.requiredFields(ObjPlannerEvent, ValidateObj, true);
    this.validatedFields = { ...checkValidation.validateData };
    if (checkValidation.isFormInvalid) {
      this.allFieldRequired = checkValidation.isFormInvalid;
      return;
    }
    ObjPlannerEvent.name = ObjPlannerEvent.title;
    ObjPlannerEvent.status = APP_CONST.ONE;
    ObjPlannerEvent.endDate = ObjPlannerEvent.endDate || ObjPlannerEvent.startDate;
    const response = await createHolidays(ObjPlannerEvent);
    if (response.status == APP_CONST.RESPONSE_200) {
      ObjPlannerEvent.id = response.data.holidayId;
      const eventToAdd = { ...this.prepareHolidayData(ObjPlannerEvent), state: 'addEvent' };
      this.$emit('openPopup', eventToAdd);
      this.closeEvent();
      this.plannerEventObj = { ...APP_CONST.PROGRAM_EVENT } as any;
    }
    else {
      if (response.status === APP_CONST.RESPONSE_500) {
        this.closeEvent();
      }
      else {
        this.errorText = response.data;
      }
    }
  }




  async updateHoliday() {
    let checkForCorrectTime = false;
    this.checkForValidityWithProgram = false;
    this.start_time_error = false;
    this.end_time_error = false;
    this.timeError = false;
    this.negativeTimeError = false;
    this.ampmError = false;
    this.start_date_error = false;
    const startTimeSplit = this.start_time.split(' ');
    const endTimeSplit = this.end_time.split(' ');
    const dateNumbers = startTimeSplit[APP_CONST.ZERO].split(':');
    const endTimeNumbers = endTimeSplit[APP_CONST.ZERO].split(':');
    const regexFilter: any = /^[-+]?[0-9]+$/;

    if (this.plannerEventObj.startDate.length === 0 || (typeof (this.plannerEventObj.startDate) == 'string' && this.plannerEventObj.startDate.includes('NaN'))) {
      this.start_date_error = true;
    }
    if (this.start_time.length > 0 && dateNumbers[APP_CONST.ZERO].match(regexFilter) && dateNumbers[APP_CONST.ONE].match(regexFilter)) {

      const newStartDate = this.plannerEventObj.startDate
        ? APP_UTILITIES.formatCorrectDate(this.plannerEventObj.startDate).split(' ')
        : '';
      this.plannerEventObj.startDate = newStartDate
        ? `${newStartDate[APP_CONST.ZERO]} ${this.convertTime12to24(this.start_time)}`
        : '';

    }
    else {
      checkForCorrectTime = true;
      this.start_time_error = true;
    }
    if (this.end_time.length > 0 && endTimeNumbers[APP_CONST.ZERO].match(regexFilter) && endTimeNumbers[APP_CONST.ONE].match(regexFilter)) {
      this.plannerEventObj.endDate = APP_UTILITIES.formatCorrectDate(this.plannerEventObj.endDate);

      const newEndDate = APP_UTILITIES.formatCorrectDate(this.plannerEventObj.endDate).split(' ');
      this.plannerEventObj.endDate = `${newEndDate[APP_CONST.ZERO]} ${this.convertTime12to24(this.end_time)}`;

    }
    else {
      checkForCorrectTime = true;
      this.end_time_error = true;
    }

    if (this.start_time.length > 0) {
      const startTime = this.start_time.split(' ');
      if (startTime[1] === 'AM' || startTime[1] === 'PM') {
        this.ampmError = false;
      }
      else {
        this.ampmError = true;
        return false;
      }
    }

    if (this.end_time.length > 0) {
      const endTime = this.end_time.split(' ');
      if (endTime[1] === 'AM' || endTime[1] === 'PM') {
        this.ampmError = false;
      }
      else {
        this.ampmError = true;
        return false;
      }
    }

    const ObjPlannerEvent = { ...this.plannerEventObj } as any;
    const ValidateObj = this.validatedFields;
    const userDetails: any = commonEntry.userDetail;
    ObjPlannerEvent['userRoles'] = userDetails.userRoles;
    ObjPlannerEvent['siteId'] = (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
      ? this.currentSiteId
      : 0;

    const checkValidation = APP_UTILITIES.requiredFields(ObjPlannerEvent, ValidateObj);
    this.validatedFields = { ...checkValidation.validateData };

    if (checkValidation.isFormInvalid) {
      this.allFieldRequired = checkValidation.isFormInvalid;
      return;
    }
    if (this.plannerEventObj.endDate.length && this.plannerEventObj.startDate.length) {
      const dateA = new Date(this.plannerEventObj.startDate);
      const dateB = new Date(this.plannerEventObj.endDate);

      const dayRelativeDifference = dateB.getHours() * 60 + dateB.getMinutes()
        - dateA.getHours() * 60 - dateA.getMinutes();
      if (dayRelativeDifference < 0) {
        this.negativeTimeError = true;
        this.allFieldRequired = APP_CONST.FALSE;
        return;
      }
      else if (dayRelativeDifference >= 0 && dayRelativeDifference < 30) {
        this.timeError = true;
        this.allFieldRequired = APP_CONST.FALSE;
        return;
      }
    }
    if (this.plannerEventObj.startDate.length === 0 || (this.plannerEventObj.title && this.plannerEventObj.title.length === 0) || checkForCorrectTime) {
      return;
    }
    ObjPlannerEvent.startTime = this.start_time;
    ObjPlannerEvent.endTime = this.end_time;

    ObjPlannerEvent.name = ObjPlannerEvent.title;
    ObjPlannerEvent.status = APP_CONST.ONE;
    ObjPlannerEvent.endDate = ObjPlannerEvent.endDate || ObjPlannerEvent.startDate;

    const response = await editHolidays(ObjPlannerEvent, ObjPlannerEvent.id);
    if (response.status == APP_CONST.RESPONSE_200) {
      const eventHoliday = this.prepareHolidayData(ObjPlannerEvent);
      const updatedEventData = { ...eventHoliday, state: 'updateEvent' };
      this.$emit('openPopup', updatedEventData);
      this.plannerEventObj = { ...APP_CONST.PROGRAM_EVENT } as any;
      this.plannerEventObj.programId = ObjPlannerEvent.programId;
      this.plannerEventObj.siteId = (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
        ? this.currentSiteId
        : 0;
      this.EventObjToUpdate.programId = ObjPlannerEvent.programId;
      this.EventObjToUpdate.siteId = (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
        ? this.currentSiteId
        : 0;
      this.updatationTime();
      this.closeEvent();
    }
    else {
      if (response.status === APP_CONST.RESPONSE_500) {
        this.closeEvent();
      }
      else {
        this.errorText = response.data;
      }
    }
  }

  mmddyyyyconverstion(dt: any) {
    dt = new Date(dt);
    return `${(dt.getMonth() + 1).toString().padStart(2, '0')}/${dt.getDate().toString().padStart(2, '0')}/${dt.getFullYear().toString().padStart(4, '0')} ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`;
  }

  prepareHolidayData(item: any) {
    const eventObj = {
      'id': item.id,
      'title': item.name,
      'location': item.location || '',
      'startDate': this.mmddyyyyconverstion(item.startDate),
      'endDate': item.endDate
        ? this.mmddyyyyconverstion(item.endDate)
        : '',
      'actualstartDate': this.mmddyyyyconverstion(item.startDate) || '',
      'actualendDate': this.mmddyyyyconverstion(item.endDate) || '',
      'displayDate': APP_UTILITIES.dayFormat(item.startDate),
      'displayMonth': APP_UTILITIES.monthFormat(item.startDate),
      'description': item.description || '',
      'status': {
        'id': 1,
        'state': 'Active'
      },
      'createdBy': item.createdBy || '',
      'createdAt': item.createdAt || '',
      'startMonth': this.deriveMonth(item.startDate),
      'year': this.getYearFromDate(item.startDate),
      'startTime': '',
      'endTime': '',
      'programId': this.getProgramId() || 0,
      'siteId': (this.currentRoleId === 6 || this.currentRoleId === 1 || this.currentRoleId === 2)
        ? this.currentSiteId
        : 0,
      'holiday': APP_CONST.TRUE,
      'startDay': APP_UTILITIES.dayFormat(item.startDate),
      'endDay': APP_UTILITIES.dayFormat(item.endDate),
      'fullMonth': APP_UTILITIES.fullMonthFormat(item.startDate),
      'fullEndMonth': APP_UTILITIES.fullMonthFormat(item.endDate)
    };
    return eventObj;
  }


  adjustFields() {
    this.validatedFields = JSON.parse(JSON.stringify(APP_CONST.VALIDATE_PROG_PLAN_EVENT));
    if (this.holidayForm) {
      this.allFieldRequired = APP_CONST.FALSE;
      this.plannerEventObj.location = '';
      this.plannerEventObj.startDate = '';
      this.start_time = this.start_time;
      this.end_time = this.end_time;
      this.timeError = false;
      this.negativeTimeError = false;
      this.ampmError = false;
    }
    else {
      this.allFieldRequired = APP_CONST.FALSE;
      this.plannerEventObj.startDate = '';
      this.plannerEventObj.endDate = '';
      this.timeError = false;
      this.negativeTimeError = false;
      this.ampmError = false;
    }
  }

  public deriveMonth = (startDate: string) => {
    if (startDate) {
      return this.getMonths[new Date(startDate).getMonth()].monthName;
    }
  };

  public getYearFromDate(data: string) {
    const date = new Date(data);
    const year = date.getFullYear();
    return year;
  }

  updatationTime() {
    this.EventObjToUpdate.createdAt = new Date() as any;
    this.plannerEventObj.createdAt = new Date() as any;
  }

  generateTimeInterval() {
    const min_interval = 15;
    let zeroth_time = 0;
    const ap = ['AM', 'PM'];


    for (let i = 0; zeroth_time < 24 * 60; i++) {
      const hh = Math.floor(zeroth_time / 60);
      const mm = (zeroth_time % 60);

      let hr;
      if (hh % 12 == 0) {
        hr = 12;
      }
      else {
        hr = hh % 12;
      }
      this.timeList[i] = ('' + (hr)).slice(-2) + ':' + ('0' + mm).slice(-2) + ' ' + ap[Math.floor(hh / 12)];
      zeroth_time = zeroth_time + min_interval;
    }
  }

  mounted() {
    this.generateTimeInterval();
  }

  scrollTime(time: string, id: string) {

    if (id == 'START') {
      this.openTimeDrop = true;
    }
    else {
      this.openEndTimeDrop = true;
    }
    setTimeout(function () {
      const times = time + id;
      const scrollEvent = document.getElementById(times);
      if (scrollEvent != null) {
        scrollEvent.scrollIntoView();
        scrollEvent.style.background = APP_CONST.STYLE.COLOR.NEUTRAL_700;
      }
    }, 100);
  }

}